import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="#">Home</Link>
            
            </li>
            
            <li>
                <a href="#aboutus">About Us</a>
                 </li>
                 <li>
                <a href="#Services">Services</a>
                 </li>
                 <li>
                <a href="#solutions">Why Choose Us?</a>
                 </li>
                 <li>
                <a href="#Contactus">FAQ</a>
                 </li>
                 <li>
                <a href="#Contactus">Contact Us</a>
                 </li>
         

           

        
        </ul>
    )
}
export default Nav;
